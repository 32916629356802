import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import * as Postmonger from 'postmonger';
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, Subject } from 'rxjs';
import { Payload } from './model/payload';
import { SfmcService } from './service/sfmc.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { Token, TokenBU, TokenField, TokenResponse } from './model/token';
import { NgZone } from '@angular/core';

declare global {
  interface Window { customNamespace: any; }
}
window.customNamespace = window.customNamespace || {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit{
  @ViewChild("validacion") validacion;
  arguments = {};
  selected: Payload = new Payload();
  tokens: Token[];
  token: Token;
  tokensBU: TokenBU[] = [{ Name: "Fidelidad", MID: "534000848" }, { Name: "B2B", MID: "534000849" }];
  tokenBU: TokenBU;
  tokensField: TokenField[];
  tokenField: TokenField;
  tokensResponses: TokenResponse[] = [{ Name: "Grupo de control", Value: "GC" }, { Name: "Grupo objetivo", Value: "GO" }]
  tokenResponse: TokenResponse;

  errorMessage: string;
  registerForm: FormGroup;
  submitted: boolean;
  connection = new Postmonger.Session();

  dataDinamic: any[] = []; //Agrupa contenido de contactos + entry data
  entryData: any[] = [];
  tokensData: any[] = [];

  faltantes: string[] = [];

  atributosRequeridosDE: string[] =  [
    "personcontactid",
    "fecha_carga",
    "id",
    "idusuarioappcopec__c",
    "personemail",
    "telefonomovil__c",
    "id_campana",
    "id_subcampana",
    "id_comunicacion",
    "id_segmento",
    "fecha_inicio_campana",
    "fecha_fin_campana",
    "insert_usuario",
    "grupo"
  ]

  constructor(private sfmcService: SfmcService,
    private notifierService: NotifierService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private zone: NgZone) {
      
      window.customNamespace.saveForm = (payload) => {
        // Since this function runs outside Angular's zone, we need to get back inside!
        this.zone.run(() => {
          this.save(payload);
        });
      }

      window.customNamespace.getLoadDataextesions = (inArguments) => {
        // Since this function runs outside Angular's zone, we need to get back inside!
        this.zone.run(() => {
          this.loadDataextesions(inArguments);
        });
      }

      window.customNamespace.getRequetedSchema = () => {
        // Since this function runs outside Angular's zone, we need to get back inside!
        this.zone.run(() => {
          var connection = new Postmonger.Session();
          connection.trigger('requestSchema')
        });
      }

      window.customNamespace.callJoinSelectedData = (entryData) => {
        // Since this function runs outside Angular's zone, we need to get back inside!
        this.zone.run(() => {
          this.entryData = entryData;
          this.joinSelectedData()
        });
      }
  }

  ngAfterViewInit(): void {
    this.connection.trigger('ready');
  }
  
  ngOnInit(): void {
    //this.loadDataextesions([]);
    this.registerForm = this.formBuilder.group({
      bu: ['', Validators.required],
      token: ['', Validators.required],
      field: ['', Validators.required],
      response: ['', Validators.required],
    });
   
    this.connection.on('initActivity', this.initialize);
    this.connection.on('requestedTokens', this.onGetTokens);
    this.connection.on('clickedNext', this.onClickedNext);
    this.connection.on('clickedBack', this.onClickedBack);
    this.connection.on('gotoStep', this.onGotoStep);
    this.connection.on('requestedDataSources', this.requestDataSources);

    this.connection.on('requestedSchema', this.requestedSchema);
    
    this.connection.on('requestedTriggerEventDefinition', this.requestedTriggerEventDefinition);
  }

  get f() { return this.registerForm.controls; }

  // addTagFn(text) {
  //   return text;
  // }

  save(payload){
    try {
      this.submitted = true;
    
      if (this.registerForm.invalid) {
        this.notifierService.notify('error', 'Por favor complete los datos marcados en rojo.');
        return;
      }

      if (!this.validData()){
        return;
      }

      this.spinner.show();
    
      var inArgs = [];
      let arg: any = {};
      arg.personcontactid = `{{${this.entryData.find(x => x.name?.toLowerCase() == "personcontactid").key}}}`;
      arg.id = `{{${this.entryData.find(x => x.name?.toLowerCase() == "id").key}}}`;
      arg.idusuarioappcopec__c = `{{${this.entryData.find(x => x.name?.toLowerCase() == "idusuarioappcopec__c").key}}}`;
      arg.personemail = `{{${this.entryData.find(x => x.name?.toLowerCase() == "personemail").key}}}`;
      arg.telefonomovil__c = `{{${this.entryData.find(x => x.name?.toLowerCase() == "telefonomovil__c").key}}}`;
      arg.id_campana = `{{${this.entryData.find(x => x.name?.toLowerCase() == "id_campana").key}}}`;
      arg.id_subcampana = `{{${this.entryData.find(x => x.name?.toLowerCase() == "id_subcampana").key}}}`; 
      arg.id_comunicacion = `{{${this.entryData.find(x => x.name?.toLowerCase() == "id_comunicacion").key}}}`; 
      arg.id_segmento = `{{${this.entryData.find(x => x.name?.toLowerCase() == "id_segmento").key}}}`; 
      arg.fecha_inicio_campana = `{{${this.entryData.find(x => x.name?.toLowerCase() == "fecha_inicio_campana").key}}}`;
      arg.fecha_fin_campana = `{{${this.entryData.find(x => x.name?.toLowerCase() == "fecha_fin_campana").key}}}`;
      arg.fecha_carga = `{{${this.entryData.find(x => x.name?.toLowerCase() == "fecha_carga").key}}}`;
      arg.insert_usuario = `{{${this.entryData.find(x => x.name?.toLowerCase() == "insert_usuario").key}}}`;
      arg.bu = this.selected.bu.MID;
      arg.token = this.selected.token.CustomerKey;
      arg.field = this.selected.field.Name;
      arg.response = this.selected.response.Value;
      inArgs.push(arg);

      console.log('////// init arguments //////');
      console.log(payload['arguments']);
      console.log('////// end arguments //////');

      console.log('////// init metaData //////');
      console.log(payload['metaData']);
      console.log('////// end metaData //////');

      console.log('////// init payload //////');
      console.log(payload);
      console.log('////// end payload //////');

      payload['arguments'].execute.inArguments = inArgs;
      payload['metaData'].isConfigured = true;
      var connection = new Postmonger.Session();
      connection.trigger('updateActivity', payload);
    } catch (error) {
      this.spinner.hide();
      this.submitted = false;
      this.notifierService.notify('error', 'Ocurrio un error inesperado al configurar esta actividad. ' + error );
    }
  }

  cambiaToken($event){
    this.selected.token = $event.item;
    this.loadFields(this.selected.token.CustomerKey, this.arguments);
  }

  cambiaBU($event){
    this.selected.bu = $event.item;
    this.loadDataextesions([]);
    //window.customNamespace.getLoadDataextesions(this.arguments);
  }

  cambiaTokenField($event){
    this.selected.field = $event.item;
  }

  cambiaTokenResponse($event){
    this.selected.response = $event.item;
  }

  //AutoComplete - TypeaHead
  @ViewChild('instanceDE') instanceDE: NgbTypeahead;

  focusDE$ = new Subject<any>();
  clickDE$ = new Subject<any>();
  formatterDE = (x: { Name: string }) => x.Name;
  searchDE = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickDE$.pipe(filter(() => !this.instanceDE.isPopupOpen()));
    const inputFocus$ = this.focusDE$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.tokens
        : this.tokens.filter(v => v.Name.toLowerCase().indexOf(typeof term === 'string' ? term.toLowerCase() : (term as HTMLInputElement).value?.toLowerCase()) > -1)))
    );
  }

  @ViewChild('instanceBU') instanceBU: NgbTypeahead;

  focusBU$ = new Subject<any>();
  clickBU$ = new Subject<any>();
  formatterBU = (x: { Name: string }) => x.Name;
  searchBU = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickBU$.pipe(filter(() => !this.instanceBU.isPopupOpen()));
    const inputFocus$ = this.focusBU$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.tokensBU
        : this.tokensBU.filter(v => v.Name.toLowerCase().indexOf(typeof term === 'string' ? term.toLowerCase() : (term as HTMLInputElement).value?.toLowerCase()) > -1)))
    );
  }

  @ViewChild('instanceFL') instanceFL: NgbTypeahead;

  focusFL$ = new Subject<any>();
  clickFL$ = new Subject<any>();
  formatterFL = (x: { Name: string }) => x.Name;
  searchFL = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickFL$.pipe(filter(() => !this.instanceFL.isPopupOpen()));
    const inputFocus$ = this.focusFL$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.tokensField
        : this.tokensField.filter(v => v.Name.toLowerCase().indexOf(typeof term === 'string' ? term.toLowerCase() : (term as HTMLInputElement).value?.toLowerCase()) > -1)))
    );
  }

  @ViewChild('instanceRP') instanceRP: NgbTypeahead;

  focusRP$ = new Subject<any>();
  clickRP$ = new Subject<any>();
  formatterRP = (x: { Name: string }) => x.Name;
  searchRP = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickRP$.pipe(filter(() => !this.instanceRP.isPopupOpen()));
    const inputFocus$ = this.focusRP$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.tokensResponses
        : this.tokensResponses.filter(v => v.Name.toLowerCase().indexOf(typeof term === 'string' ? term.toLowerCase() : (term as HTMLInputElement).value?.toLowerCase()) > -1)))
    );
  }


  





  ////////////////////////////////////////////////////////// postmonger
  payload = {};
  hasInArguments = false;
  eventDefinitionKey = "";
  journeydata = {};
  authTokens = {fueltoken: ""};
  //journeySchema = {};
  
  initialize(data) {
    console.log('*** initialize ***');

    try {
      console.log(data);

      if (data) {
          this.payload = data;
      }
      
      this.hasInArguments = Boolean(
        this.payload['arguments'] &&
        this.payload['arguments'].execute &&
        this.payload['arguments'].execute.inArguments &&
        this.payload['arguments'].execute.inArguments.length > 0
      );
  
      this.arguments = this.hasInArguments ? this.payload['arguments'].execute.inArguments : {};
      // var inArguments = this.hasInArguments ? this.payload['arguments'].execute.inArguments : {};

      // if (inArguments && Array.isArray(inArguments) && inArguments.length > 0){
      //   this.selected = new Payload();
      //   this.selected.token = inArguments[0].token;
      //   this.selected.field = inArguments[0].field;
      //   this.selected.response = inArguments[0].response;
      // }

      window.customNamespace.getRequetedSchema();
      if (this.arguments && Array.isArray(this.arguments) && this.arguments.length > 0){
        window.customNamespace.getLoadDataextesions(this.arguments);
      }
    } catch (error) {
      console.log('*** initialize Error***');
      console.log(error);
    }
  }

  loadDataextesions(inArguments){
    console.log('*** LoadDataextesions ***');
    try {
      console.log(inArguments);
      if (inArguments && Array.isArray(inArguments) && inArguments.length > 0){
        this.selected.bu = this.tokensBU.find(x => x.MID == inArguments[0].bu);    
      }

      let url = "/sfmc/dataextesions/?business_unit=" + this.selected.bu.MID;
      this.spinner.show();
  
      this.sfmcService.getTokens(url).subscribe(
        (result) => {
          this.tokens = result["soap:Envelope"]["soap:Body"]["RetrieveResponseMsg"]["Results"]
          //this.tokensData = this.tokens.map( x => { Name: x.Name });
          if (inArguments && Array.isArray(inArguments) && inArguments.length > 0){
            this.selected.token = this.tokens.find(x => x.CustomerKey == inArguments[0].token);
            this.loadFields(inArguments[0].token, inArguments);
          } else { //Establece por defecto la DE que se va a usar.  Romina pidio que no fuera seleccionable
            this.selected.token = this.tokens.find(x => x.Name === "Modelo_Usuario_CustomActivity");
            this.loadFields(this.selected.token.CustomerKey, null);
          }
          this.spinner.hide();
        }
      );
    } catch (error) {
      console.log('*** LoadDataextesions Error***');
      console.log(error);
    }
  }

  loadFields(deExternalKey: string, inArguments: {}){
    console.log('*** LoadDataextesions ***');
    try {
      console.log(deExternalKey);

      let url = "/sfmc/fields/?de_external_key=" + deExternalKey + "&business_unit=" + this.selected.bu.MID;
  
      this.spinner.show();
  
      this.sfmcService.getTokens(url).subscribe(
        (result) => {
          this.tokensField = result["soap:Envelope"]["soap:Body"]["RetrieveResponseMsg"]["Results"];
          if (inArguments && Array.isArray(inArguments) && inArguments.length > 0){
            this.selected.field = this.tokensField.find(x => x.Name == inArguments[0].field);
            this.selected.response = this.tokensResponses.find(x => x.Value == inArguments[0].response);
          }
          this.spinner.hide();
        }
      );
    } catch (error) {
      console.log('*** LoadDataextesions Error***');
      console.log(error);
    }
  }

  onGetTokens(tokens) {
    console.log('*** onGetTokens ***');
    try {
      console.log(tokens);
      this.authTokens = tokens;
    } catch (error) {
      console.log('*** onGetTokens Error***');
      console.log(error);
    }
  }

  onClickedNext () {
    console.log('*** onClickedNext ***');
    try {
      window.customNamespace.saveForm(this.payload);
    } catch (error) {
      console.log('*** onClickedNext Error***');
      console.log(error);
    }
  }

  onClickedBack(arg0: string, onClickedBack: any) {
    console.log('*** onClickedBack ***');
    try {
      console.log(arg0);
      console.log(onClickedBack);
      //this.connection.trigger('prevStep');
    } catch (error) {
      console.log('*** onClickedBack Error***');
      console.log(error);
    }
  }

  onGotoStep(step: any) {
    console.log('*** onGotoStep ***');
    try {
      console.log(step);
      //this.connection.trigger('ready');
    } catch (error) {
      console.log('*** onGotoStep Error***');
      console.log(error);
    }
  }

  requestDataSources(dataSources: any) {
    try {
      this.journeydata = dataSources;
    } catch (error) {
      console.log('*** requestDataSources Error***');
      console.log(error);
    }
  }

  requestedSchema(data) {
    console.log('*** Inicio Schema ***');
    //this.entryData = data['schema'].map( x => `{{${x.key}}}`);
    this.entryData = data['schema'];
    window.customNamespace.callJoinSelectedData(this.entryData);
    console.log('*** Fin Schema ***');
  }

  joinSelectedData() {
    const tokenList = this.tokensData || [];
    const entryDataList = this.entryData || [];
    this.dataDinamic = tokenList.concat(entryDataList); 
  
    return this.validData();
  }  

  requestedTriggerEventDefinition(eventDefinitionModel: any) {
    console.log('*** requestedTriggerEventDefinition ***');
    try {
      console.log(eventDefinitionModel);
      this.eventDefinitionKey = eventDefinitionModel.eventDefinitionKey;
    } catch (error) {
      console.log('*** requestedTriggerEventDefinition Error***');
      console.log(error);
    }
  }

  validData(){
    this.faltantes = this.atributosRequeridosDE.filter(x => !this.entryData.map(x => x.name && x.name.toLowerCase()).includes(x));

    if ( this.entryData.length == 0 || this.faltantes.length > 0 ){
      this.modalService.open(this.validacion, {ariaLabelledBy: 'modal-basic-title'});
      //this.notifierService.notify('error', 'La DE de entrada debe incluir al menos los siguientes atributos');
      return false;
    }

    return true;
  }
}
