<notifier-container></notifier-container>

<ng-template #validacion let-modal>
  <div class="modal-header">
    <h3 class="modal-title">Atención</h3>
  </div>
  <div class="modal-body">
    <p>Los siguientes campos deben estar en la DE de entrada al flujo, esten estos poblados o no</p>
    <ul>
      <li *ngFor="let item of faltantes">{{ item }}</li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="modal.close()">Cerrar</button>
  </div>
</ng-template>

<form [formGroup]="registerForm">
  <div class="p-3">v6
    <!-- <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label">* Titulo</label>
        <ng-select id="titulo" formControlName="titulo" [ngClass]="{ 'is-invalid': submitted && f.titulo.errors }"
            [items]="dataDinamic"
            [addTag]="addTagFn"
            [hideSelected]="true"
            [multiple]="true"
            bindLabel="name"
            [(ngModel)]="selected.titulo">
        </ng-select>
      </div>
    </div> -->

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group clear">
          <label for="bu" class="col-form-label">* Business Unit</label>
          <div>
            <input id="bu"
                type="text"
                class="form-control"
                [(ngModel)]="selected.bu"
                (focus)="focusBU$.next($event.target)"
                (click)="clickBU$.next($event.target)"
                #instanceBU="ngbTypeahead"
                [ngbTypeahead]="searchBU"
                [resultFormatter]="formatterBU"
                [inputFormatter]="formatterBU"
                (selectItem)="cambiaBU($event)"
                formControlName="bu"
                style="display: inline-block;"
                [ngClass]="{ 'is-invalid': submitted && f.bu.errors }"/>
  
            <button *ngIf="tokenBU" type="button" class="btn btn-link btn-icon mr-2 mb-2 clear" (click)="tokenBU=null">
              <i class="icon ion-ios-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group clear">
          <label for="token" class="col-form-label">* Data extension</label>
          <div>
            <input id="token"
                type="text"
                class="form-control"
                [(ngModel)]="selected.token"
                (focus)="focusDE$.next($event.target)"
                (click)="clickDE$.next($event.target)"
                #instanceDE="ngbTypeahead"
                [ngbTypeahead]="searchDE"
                [resultFormatter]="formatterDE"
                [inputFormatter]="formatterDE"
                (selectItem)="cambiaToken($event)"
                formControlName="token"
                style="display: inline-block;"
                [ngClass]="{ 'is-invalid': submitted && f.token.errors }" 
                readonly="readonly"
                 />
  
            <button *ngIf="token" type="button" class="btn btn-link btn-icon mr-2 mb-2 clear" (click)="token=null">
              <i class="icon ion-ios-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group clear">
          <label for="field" class="col-form-label">* Campo</label>
          <div>
            <input id="field"
                type="text"
                class="form-control"
                [(ngModel)]="selected.field"
                (focus)="focusFL$.next($event.target)"
                (click)="clickFL$.next($event.target)"
                #instanceFL="ngbTypeahead"
                [ngbTypeahead]="searchFL"
                [resultFormatter]="formatterFL"
                [inputFormatter]="formatterFL"
                (selectItem)="cambiaTokenField($event)"
                formControlName="field"
                style="display: inline-block;"
                [ngClass]="{ 'is-invalid': submitted && f.field.errors }"/>
  
            <button *ngIf="tokenField" type="button" class="btn btn-link btn-icon mr-2 mb-2 clear" (click)="tokenField=null">
              <i class="icon ion-ios-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group clear">
          <label for="response" class="col-form-label">* Valor</label>
          <div>
            <input id="response"
                type="text"
                class="form-control"
                [(ngModel)]="selected.response"
                (focus)="focusRP$.next($event.target)"
                (click)="clickRP$.next($event.target)"
                #instanceRP="ngbTypeahead"
                [ngbTypeahead]="searchRP"
                [resultFormatter]="formatterRP"
                [inputFormatter]="formatterRP"
                (selectItem)="cambiaTokenResponse($event)"
                formControlName="response"
                style="display: inline-block;"
                [ngClass]="{ 'is-invalid': submitted && f.response.errors }"/>
  
            <button *ngIf="tokenResponse" type="button" class="btn btn-link btn-icon mr-2 mb-2 clear" (click)="tokenResponse=null">
              <i class="icon ion-ios-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-beat" [fullScreen]="true">
  <p style="color: white">Loading...</p>
</ngx-spinner>